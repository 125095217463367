export var SearchList = [
  { labe: '订单编号', code: 'xsdBh', type: 'input' },
  { labe: '物料编号', code: 'cgdBh', type: 'input' }
]
export var SearchData = {
  xsdBh: '',
  cgdBh: ''
}

export var tableField = [
  { type: 'input', selectType: 'selection', width: '', reserveSelection: true },
  { label: '订单编号', code: 'orderCode', type: 'input', width: '' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '' },
  { label: '采购员', code: 'buyerName', type: 'input', width: '' }
]

export var tableData = []
